var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-row',{class:("mt-3 " + (_vm.proposal ? 'col-8' : 'col-12 col-lg-8 col-xl-5'))},[_c('b-col',{staticClass:"col-12 text-center"},[_c('h1',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('creator.whatType')))]),_c('p',{staticClass:"avenir-medium"},[_vm._v(_vm._s(_vm.$t('creator.whatTypeDescrip')))])]),_c('b-col',{staticClass:"col-12 mt-2"},[_c(_vm.v_select,{tag:"component",staticClass:"w-100 mb-1 select-edit-category",attrs:{"id":"select-edit-category","label":"text","options":_vm.$t('creator.type_of_work')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"avenir-medium"},[_vm._v(_vm._s(text))])]}},{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}]),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c(_vm.v_select,{tag:"component",staticClass:"w-100 mb-2",attrs:{"label":"text","options":this.$t(("creator.focus_in_" + (_vm.category.value)))},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"avenir-medium"},[_vm._v(_vm._s(text))])]}},{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}]),model:{value:(_vm.subcategory),callback:function ($$v) {_vm.subcategory=$$v},expression:"subcategory"}})],1),_c('hr',{staticClass:"my-2"}),(_vm.show_alert_change)?_c('b-col',{staticClass:"p-1 border rounded"},[_c('b-form-checkbox',{attrs:{"value":"accepted","unchecked-value":"not_accepted"},model:{value:(_vm.change_category),callback:function ($$v) {_vm.change_category=$$v},expression:"change_category"}},[_vm._v(" Changing your Category & Subcategory will reset your Proposal, please confirm. ")])],1):_vm._e(),_c('b-col',{staticClass:"col-12 d-flex justify-content-center"},[_c('b-button',{staticClass:"blue-button-next-step my-2",attrs:{"variant":"blue-button-next-step"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('creator.butonNext')))])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }