<template>
  <section>
    <div class="d-flex justify-content-center">
        <b-row :class="`mt-3 ${proposal ? 'col-8' : 'col-12 col-lg-8 col-xl-5'}`">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">{{ $t('creator.whatType')}}</h1>
            <p class="avenir-medium">{{ $t('creator.whatTypeDescrip')}}</p>
          </b-col>
          <b-col class="col-12 mt-2"> 
            <component id="select-edit-category" label="text" :is="v_select" v-model="category" class="w-100 mb-1 select-edit-category" :options="$t('creator.type_of_work')">
              <template #option="{ text }">
                <span class="avenir-medium">{{text}}</span>
              </template>
              <template #selected-option="{ text }">
                <span>{{text}}</span>
              </template>
            </component>

            <component label="text" :is="v_select" v-model="subcategory" class="w-100 mb-2" :options="this.$t(`creator.focus_in_${category.value}`)">
              <template #option="{ text }">
                <span class="avenir-medium">{{text}}</span>
              </template>
              <template #selected-option="{ text }">
                <span>{{text}}</span>
              </template>
            </component>
          </b-col>
          <hr class="my-2">
          <b-col v-if="show_alert_change" class="p-1 border rounded">
            <b-form-checkbox
              v-model="change_category"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Changing your Category & Subcategory will reset your Proposal, please confirm.
            </b-form-checkbox>
          </b-col>
          <b-col class="col-12 d-flex justify-content-center">
            <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save">{{$t('creator.butonNext')}}</b-button>
          </b-col>
        </b-row>
      </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';

export default {
  name: 'categorySubcategory',
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
  props: {
    proposal_dad: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      v_select: null,
      category: null,
      subcategory: null,
      show_alert_change: false,
      change_category: null,
      steps: null,
    }
  },
  computed: {
    proposal() {
      return this.$route.params.proposal
    }
  },
  created() {
    this.getData();
  },
  async mounted() {
    this.v_select = (await import('vue-select')).default;
  },
  methods: {
    updateProposal(proposal) {
      this.$emit('update_proposal', proposal);
    },
    getData() {
      if (localStorage.getItem('steps') && !this.proposal) {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.category = this.steps.work_type;
        this.subcategory = this.$t(`creator.focus_in_${this.category.value}`).find((item) => item.value === this.steps.content_type.value)
      } else {
        this.category = this.proposal ? this.$t('creator.type_of_work').find((item) => item.value === this.proposal.work_type) : this.$t('creator.type_of_work')[0];
        this.subcategory = this.proposal ? this.$t(`creator.focus_in_${this.category.value}`).find((item) => item.value === this.proposal.content_type) : this.$t(`creator.focus_in_${this.category.value}`)[0]
      }
    },
    save() {
      if (this.proposal) {
        if (this.category.value === this.proposal.work_type && this.subcategory.value === this.proposal.content_type) {        
          this.$emit('update_proposal', this.proposal)
        } else if (this.change_category) {
          this.steps = {work_type: this.category, content_type: this.subcategory}
          localStorage.setItem('steps', JSON.stringify(this.steps));
          this.$emit('change_view_connection')
        }
      } else {
        if (this.steps === null) {
          this.steps = {work_type: this.category, content_type: this.subcategory}
        } else {
          this.steps.work_type = this.category;
          this.steps.content_type = this.subcategory;
        }
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'steps_proposal', params: {step: 'connection'}});
      }
    }
  },
  watch: {
    category(new_val, old_value) {
      if (old_value) this.subcategory = this.$t(`creator.focus_in_${new_val.value}`)[0]
      if (this.proposal && new_val && new_val.value !== this.proposal.work_type) {
        this.show_alert_change = true;
      } else {
        this.show_alert_change = false;
      }
    },
    subcategory(new_val) {
      if (this.proposal && new_val && new_val.value !== this.proposal.content_type) {
        this.show_alert_change = true;
      } else {
        this.show_alert_change = false;
      }
    }
  }
}
</script>
<style>
.vs--single .vs__dropdown-toggle {
  padding: 0.7em;
}
</style>